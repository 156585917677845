import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import Article from "~/models/article"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ArticlesBlock from "~/ui/compositions/article/list"

import {
  ArticleListPageQuery,
  ContentfulPage,
  ContentfulArticle,
} from "../../../types/graphql-types"

type Props = {
  data: ArticleListPageQuery
}

function ArticleListPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const articles = data.allContentfulArticle.nodes.map(article => {
    return new Article(article as ContentfulArticle)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
            <React.Fragment>
              <PageCoverBlock {...block.props} />
              <div className="mb-2 mt-2">
                <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                  <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                </div>
              </div>
            </React.Fragment>
            }

            {block.name == "Articles" && (
              <ArticlesBlock articles={articles} {...block.props} />
            )}

            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default ArticleListPage

export const query = graphql`
  query ArticleListPage {
    contentfulPage(url: { pathname: { eq: "/articles/" } }) {
      ...PageFields
    }
    allContentfulArticle(limit: 180) {
      nodes {
        ...ArticleFields
      }
    }
  }
`
